
export default {
    name: "JobFairSeoContent",
    components: {},
    data() {
        return {
            logos: [
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/agentur.png"),
                    alt: "Agentur für Arbeit",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/auto_halbig.png"),
                    alt: "Auto Halbig",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/autohaus wuest.png"),
                    alt: "Autohasu Wuest",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/Bosch_symbol_logo_black_red_DE-transparent.png"),
                    alt: "Bosch",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/GAD.png"),
                    alt: "GAD",
                },

                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/Herzog_Logo.jpg"),
                    alt: "Herzog",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/hetzner.png"),
                    alt: "Hetzner",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/index.png"),
                    alt: "Plastic",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/oechsler.png"),
                    alt: "Oechsler",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/treukontax.png"),
                    alt: "Treukontax",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/vr-bank.png"),
                    alt: "VR Bank",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/verpalin.png"),
                    alt: "Verpalin",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/HWK_MF_CO_10.jpg"),
                    alt: "HWK",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/10548761_altmuehltaler-mineralbrunnen.png"),
                    alt: "Altmuehltaler",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/Gentherm_hori_logo_fc_rgb.png"),
                    alt: "Gentherm",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/Logo_rot GutmannAG.png"),
                    alt: "Gutmann",
                },
                {
                    src: require("@/assets/images/fair/altmuehlfranken/new/VRBisF_Logo.jpg"),
                    alt: "VR",
                },
            ],
        }
    },
    methods: {},
}
